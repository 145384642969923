<template>
    <button
        id="back-to-top-button"
        class="fixed block w-12 h-12 right-4 bottom-4 bg-black opacity-0 -z-10 border-0 rounded shadow text-2xl"
        :class="{visible: isVisible}"
        @click="backToTop"
    >
        <i
            class="marso-icon-chevron-up text-gray"
            aria-hidden="true"
        ></i>
    </button>
</template>

<script>
import {defineComponent, nextTick, onMounted, ref} from 'vue';

export default defineComponent({
    name: "BackToTop",
    setup() {
        const isVisible = ref(false);

        onMounted(() => {
            nextTick(() => {
                document.addEventListener("scroll", () => {
                    isVisible.value =
                        Math.max(
                            document.body.scrollTop,
                            document.documentElement.scrollTop
                        ) > 250;
                });
            });
        });

        const backToTop = function () {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        };

        return {
            isVisible,
            backToTop
        };
    }
});
</script>

<style lang="scss">
html,
body {
    scroll-behavior: smooth;
}
</style>

<style lang="scss" scoped>
#back-to-top-button {
    &.visible {
        @apply opacity-80 z-10 transition-opacity duration-300 ease-in;

        &:hover {
            @apply opacity-100;
        }
    }
}
</style>
