"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productListFields = void 0;
const shop_1 = require("../../common/utils/shop");
const index_1 = require("../../common/i18n/index");
exports.productListFields = [
    {
        key: "productName",
        value: (item) => {
            return `
                <div class="product-name cursor-pointer inline-flex items-center">
                    <div class="pr-3 flex justify-center items-center textbox textbox-right"
                        data-text="${(0, index_1.trans)("product_info", "Product info", "tyre_search")}">
                        <span class="icon marso-icon-info-circle text-2xl text-blue" ></span>
                    </div>
                    <div>
                        <div class="font-bold">${item.productName}</div>
                    </div>
                </div>`;
        },
        label: (0, index_1.trans)("product_name", "Product name", "tyre_search"),
        visible: true,
        sortable: true,
        sortingFunction: (productA, productB) => {
            return productA.productName.localeCompare(productB.productName);
        },
    },
    {
        key: "priceList",
        visible: true,
        value: (item, args) => {
            let ret = "";
            let priceEcoNet = item.prices.priceEcoNet;
            let price = item.prices.priceListNet;
            if (typeof price !== "number") {
                price = parseFloat(price);
            }
            let priceDiscountNet = item.prices.priceDiscountNet;
            if (typeof priceDiscountNet !== "number") {
                priceDiscountNet = parseFloat(priceDiscountNet);
            }
            let currencyCode = item.prices.currencyCode;
            if (args.customerCurrencyCode) {
                currencyCode = args.customerCurrencyCode;
            }
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent && typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            if (args.showGrossPriceToggle) {
                // show gross prices
                if (priceVatPercent) {
                    ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(price + priceEcoNet, currencyCode, { vatPercent: priceVatPercent })}</div>`;
                }
            }
            else {
                // show net prices
                ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(price + priceEcoNet, currencyCode)}</div>`;
            }
            let discountPercent = (100 - priceDiscountNet / (item.prices.priceListNet / 100)) / 100;
            if (discountPercent > 0) {
                ret = `${ret}<div class="text-red-dark"><i class="hide-foreign-customer marso-icon-promotion_gift"></i> ${(0, index_1.n)(discountPercent, "percent")}</div>`;
            }
            return ret;
        },
        label: function (args) {
            let ret = `<div>${(0, index_1.trans)("list_price", "List price", "tyre_search")}</div>`;
            if (args.showGrossPriceToggle) {
                ret = `${ret}
                    <div v-if="showGrossPriceLabel" class="text-xs ml-1">
                        ${(0, index_1.trans)("gross_list_price", "gross", "tyre_search")}
                    </div>
                `;
            }
            else {
                ret = `${ret}<div v-else class="text-xs ml-1">${(0, index_1.trans)("net_list_price", "net", "tyre_search")}</div>`;
            }
            return ret;
        },
        sortable: true,
        sortingFunction: (productA, productB) => {
            let priceListNetA;
            if (typeof productA.prices.priceListNet !== "number") {
                priceListNetA = parseFloat(productA.prices.priceListNet);
            }
            else {
                priceListNetA = productA.prices.priceListNet;
            }
            let priceListNetB;
            if (typeof productB.prices.priceListNet !== "number") {
                priceListNetB = parseFloat(productB.prices.priceListNet);
            }
            else {
                priceListNetB = productB.prices.priceListNet;
            }
            return priceListNetA - priceListNetB;
        },
    },
    {
        key: "priceDiscount",
        visible: true,
        label: (args) => {
            let ret = `<div>${(0, index_1.trans)("wholesale_price", "Wholesale price", "tyre_search")}</div>`;
            if (args.showGrossPriceToggle) {
                ret = `${ret}
                    <div v-if="showGrossPriceToggle" class="text-xs ml-1 hide-foreign-customer">
                        ${(0, index_1.trans)("gross_wholesale_price", "gross", "tyre_search")}
                    </div>
                `;
            }
            else {
                ret = `${ret}<div v-else class="text-xs ml-1">${(0, index_1.trans)("net_wholesale_price", "net", "tyre_search")}</div>`;
            }
            return ret;
        },
        value: (item, args) => {
            let price = item.prices.priceDiscountNet;
            let priceEcoNet = item.prices.priceEcoNet;
            if (typeof price !== "number") {
                price = parseFloat(price);
            }
            let currencyCode = item.prices.currencyCode;
            if (args.customerCurrencyCode) {
                currencyCode = args.customerCurrencyCode;
            }
            let ret = "<div>";
            let discountColorClass = '';
            if (item.prices.needDiscountDisplay) {
                discountColorClass = 'text-red-dark';
            }
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent && typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            if (args.showGrossPriceToggle) {
                // show gross prices
                if (item.prices.priceEprNet) {
                    // price gross without epr
                    if (priceVatPercent) {
                        ret = `${ret}<div class="${discountColorClass}">${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}</div>`;
                    }
                    // epr gross price
                    ret = `${ret}
                    <div class="text-sm">
                        <i class="relative top-0.5 marso-icon-recycle tooltip text-green"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}
                    </div>
                `;
                }
                // gross full price
                if (priceVatPercent) {
                    ret = `${ret}<div class="font-bold ${discountColorClass}">${(0, shop_1.formatPrice)(price + priceEcoNet, currencyCode, { vatPercent: priceVatPercent })}</div>`;
                }
            }
            else {
                // show net prices
                if (item.prices.priceEprNet) {
                    // price net without epr
                    ret = `${ret}<div class="${discountColorClass}">${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode)}</div>`;
                    // epr net price
                    ret = `${ret}
                    <div class="text-sm">
                        <i class="relative top-0.5 marso-icon-recycle tooltip text-green"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode)}
                    </div>
                `;
                }
                // net full price
                ret = `${ret}<div class="font-bold ${discountColorClass}">${(0, shop_1.formatPrice)(price + priceEcoNet, currencyCode)}</div>`;
            }
            ret = `${ret}</div>`;
            return ret;
        },
        sortable: true,
        sortingFunction: (productA, productB) => {
            let priceDiscountNetA;
            if (typeof productA.prices.priceDiscountNet !== "number") {
                priceDiscountNetA = parseFloat(productA.prices.priceDiscountNet);
            }
            else {
                priceDiscountNetA = productA.prices.priceDiscountNet;
            }
            let priceDiscountNetB;
            if (typeof productB.prices.priceDiscountNet !== "number") {
                priceDiscountNetB = parseFloat(productB.prices.priceDiscountNet);
            }
            else {
                priceDiscountNetB = productB.prices.priceDiscountNet;
            }
            return priceDiscountNetA - priceDiscountNetB;
        },
    },
    {
        key: "priceRetail",
        visible: false,
        label: (args) => {
            let ret = `<div>${(0, index_1.trans)("retail_price", "Retail price", "tyre_search")}</div>`;
            if (args.showGrossPriceToggle) {
                ret = `${ret}
                    <div v-if="showGrossLabel" class="text-xs ml-1">
                        ${(0, index_1.trans)("gross_retail_price", "gross", "tyre_search")}
                    </div>
                `;
            }
            else {
                ret = `${ret}<div v-else class="text-xs ml-1">${(0, index_1.trans)("net_retail_price", "net", "tyre_search")}</div>`;
            }
            return ret;
        },
        value: (item, args) => {
            let ret = "";
            let priceEcoNet = item.prices.priceEcoNet;
            let price = item.prices.priceRetailNet;
            if (typeof price !== "number") {
                price = parseFloat(price);
            }
            let currencyCode = item.prices.currencyCode;
            if (args.customerCurrencyCode) {
                currencyCode = args.customerCurrencyCode;
            }
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent && typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            if (args.showGrossPriceToggle) {
                // show gross price
                if (item.prices.priceEprNet) {
                    // price gross without epr
                    if (priceVatPercent) {
                        ret = `${ret}<div>${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}</div>`;
                    }
                    // epr gross price
                    ret = `${ret}
                    <div class="text-sm">
                        <i class="relative top-0.5 marso-icon-recycle tooltip text-green"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}
                    </div>
                `;
                }
                if (priceVatPercent) {
                    // gross full price
                    ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(price + priceEcoNet, currencyCode, { vatPercent: priceVatPercent })}</div>`;
                }
            }
            else {
                // show net price
                if (item.prices.priceEprNet) {
                    // price net without epr
                    ret = `${ret}<div>${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode)}</div>`;
                    // epr net price
                    ret = `${ret}
                    <div class="text-sm">
                        <i class="relative top-0.5 marso-icon-recycle tooltip text-green"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode)}
                    </div>
                `;
                }
                // net full price
                ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(price + priceEcoNet, currencyCode)}</div>`;
            }
            return ret;
        },
        sortable: true,
        sortingFunction: (productA, productB) => {
            let priceRetailNetA;
            if (typeof productA.prices.priceRetailNet !== "number") {
                priceRetailNetA = parseFloat(productA.prices.priceRetailNet);
            }
            else {
                priceRetailNetA = productA.prices.priceRetailNet;
            }
            let priceRetailNetB;
            if (typeof productB.prices.priceNet !== "number") {
                priceRetailNetB = parseFloat(productB.prices.priceRetailNet);
            }
            else {
                priceRetailNetB = productB.prices.priceRetailNet;
            }
            return priceRetailNetA - priceRetailNetB;
        },
    },
    {
        key: "priceCustom",
        visible: false,
        label: (args) => {
            let ret = `<div>${(0, index_1.trans)("custom_price", "Custom price", "tyre_search")}</div>`;
            if (args.showGrossPriceToggle) {
                ret = `${ret}
                    <div v-if="showGrossLabel" class="text-xs ml-1">
                        ${(0, index_1.trans)("gross_custom_price", "gross", "tyre_search")}
                    </div>
                `;
            }
            else {
                ret = `${ret}<div v-else class="text-xs ml-1">${(0, index_1.trans)("net_custom_price", "net", "tyre_search")}</div>`;
            }
            return ret;
        },
        value: (item, args) => {
            let ret = "";
            let price;
            if (item.prices.priceCustomNet !== 0) {
                price = item.prices.priceCustomNet;
            }
            else {
                price = item.prices.priceRetailNet;
            }
            if (typeof price !== "number") {
                price = parseFloat(price);
            }
            let currencyCode = item.prices.currencyCode;
            if (args.customerCurrencyCode) {
                currencyCode = args.customerCurrencyCode;
            }
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent && typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            if (args.showGrossPriceToggle) {
                // gross full price
                if (priceVatPercent) {
                    if (item.prices.priceCustomNetId) {
                        const priceText = args.pricingFixed !== 0 ?
                            `${args.pricingBrand} + ${args.pricingDiameter}" ->
                            +${(0, shop_1.formatPrice)(args.pricingFixed, currencyCode)}` :
                            `${args.pricingBrand} + ${args.pricingDiameter}" ->
                            +${args.pricingPercent}%`;
                        // net full price with info icon
                        ret = `${ret}
                        <div class="font-bold">
                            <i
                                class="pricing-info relative top-0.5 marso-icon-info-circle tooltip text-blue"
                                data-pricing-id="${item.prices.priceCustomNetId}"
                                data-item-id="${item.id}"
                            >
                                <div class="!flex !flex-col !gap-2 tooltip-content">
                                    <div class="text-base self-center">
                                        ${(0, index_1.trans)("pricing_information", "Pricing information", "tyre_search")}
                                    </div>
                                    <div>ID: ${item.prices.priceCustomNetId}</div>
                                    <div>${(0, index_1.trans)("name", "Name", "tyre_search")}: ${args.pricingName}</div>
                                    <div>${priceText}</div>
                                    <button
                                        class="edit-pricing btn btn-rounded btn-primary self-center mt-2 mb-2"
                                        data-pricing-id="${item.prices.priceCustomNetId}"
                                    >
                                        <i class="marso-icon-pencil"></i>
                                        <span>${(0, index_1.trans)("edit", "Edit", "tyre_search")}</span>
                                    </button>
                                </div>
                            </i>️
                            ${(0, shop_1.formatPrice)(price, currencyCode, { vatPercent: priceVatPercent })}
                        </div>`;
                    }
                    else {
                        // gross full price without info icon
                        ret = `${ret}
                            <div class="font-bold">
                                ${(0, shop_1.formatPrice)(price, currencyCode, { vatPercent: priceVatPercent })}
                            </div>`;
                    }
                }
            }
            else {
                // net full price
                if (item.prices.priceCustomNetId) {
                    const priceText = args.pricingFixed !== 0 ?
                        `${args.pricingBrand} + ${args.pricingDiameter}" ->
                            +${(0, shop_1.formatPrice)(args.pricingFixed, currencyCode)}` :
                        `${args.pricingBrand} + ${args.pricingDiameter}" ->
                            +${args.pricingPercent}%`;
                    // net full price with info icon
                    ret = `${ret}
                    <div class="font-bold">
                        <i
                            class="pricing-info relative top-0.5 marso-icon-info-circle tooltip text-blue"
                            data-pricing-id="${item.prices.priceCustomNetId}"
                            data-item-id="${item.id}"
                        >
                            <div class="!flex !flex-col !gap-2 tooltip-content">
                                <div class="text-base self-center">
                                    ${(0, index_1.trans)("pricing_information", "Pricing information", "tyre_search")}
                                </div>
                                <div>ID: ${item.prices.priceCustomNetId}</div>
                                <div>${(0, index_1.trans)("name", "Name", "tyre_search")}: ${args.pricingName}</div>
                                <div>${priceText}</div>
                                <button
                                    class="edit-pricing btn btn-rounded btn-primary self-center mt-2 mb-2"
                                    data-pricing-id="${item.prices.priceCustomNetId}"
                                >
                                    <i class="marso-icon-pencil"></i>
                                    <span>${(0, index_1.trans)("edit", "Edit", "tyre_search")}</span>
                                </button>
                            </div>
                        </i>️
                        ${(0, shop_1.formatPrice)(price, currencyCode)}
                    </div>`;
                }
                else {
                    // net full price without info icon
                    ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(price, currencyCode)}</div>`;
                }
            }
            return ret;
        },
        sortable: true,
        sortingFunction: (productA, productB) => {
            let priceCustomNetA;
            if (typeof productA.prices.priceCustomNet !== "number") {
                priceCustomNetA = parseFloat(productA.prices.priceCustomNet);
            }
            else {
                priceCustomNetA = productA.prices.priceCustomNet;
            }
            let priceCustomNetB;
            if (typeof productB.prices.priceNet !== "number") {
                priceCustomNetB = parseFloat(productB.prices.priceCustomNet);
            }
            else {
                priceCustomNetB = productB.prices.priceCustomNet;
            }
            return priceCustomNetA - priceCustomNetB;
        },
    },
    {
        key: "stock",
        visible: true,
        label: (0, index_1.trans)("stock", "Stock", "tyre_search"),
        value: (item) => {
            let cell = [];
            let stocks = item.stocks;
            let detailsCell = [];
            if ("undefined" !== typeof stocks.stockDetails) {
                stocks.stockDetails.forEach((item) => {
                    detailsCell.push(`
                        <div class="grid grid-flow-col">
                            <span class="whitespace-nowrap pr-2" title="${item.name}">${item.name}</span>
                            <span class="font-bold text-right">${item.stock}</span>
                        </div>
                    `);
                });
            }
            if ("undefined" !== typeof stocks.stockDetailsStatus) {
                if (detailsCell.length) {
                    cell.push(`<div
                        class="${stocks.stockDetailsStatus} stock-details-box
                        absolute p-2 bg-blue rounded min-w-full -right-4 text-white shadow z-10">
                        ${detailsCell.join("")}
                    </div>`);
                }
                else {
                    cell.push(`<div
                        class="${stocks.stockDetailsStatus} stock-details-box
                        absolute p-2 bg-blue rounded min-w-full -right-4 text-white shadow z-10 text-center text-xl">
                        <span class="icon marso-icon-tyrestack"></span>
                    </div>`);
                }
            }
            if ("undefined" !== typeof stocks.stockCountry &&
                0 < stocks.stockCountry) {
                cell.push(`<div class="grid grid-flow-col">
                    <span class="show-stock-details text-blue font-bold cursor-pointer">
                        ${(0, index_1.trans)("country", "country", "tyre_search")}
                    </span>
                    <span class="font-bold text-right">${stocks.stockCountry}${stocks.stockCountryMax ? "+" : ""}</span>
                </div>`);
            }
            if ("undefined" !== typeof stocks.stockLocal &&
                0 < stocks.stockLocal) {
                cell.push(`<div class="grid grid-flow-col">
                    <span>${(0, index_1.trans)("local", "of which local", "tyre_search")}</span>
                    <span class="font-bold text-right">${stocks.stockLocal}${stocks.stockLocalMax ? "+" : ""}</span>
                </div>`);
            }
            // Nincs szükség expressz készlet megjelenítésére, mert ez DH telephelyen egyezik a helyi készlettel.
            // if (
            //     "undefined" !== typeof stocks.stockExpress &&
            //     0 < stocks.stockExpress
            // ) {
            //     cell.push(`<div class="grid grid-flow-col">
            //         <span>${trans("express", "local", "tyre_search")}</span>
            //         <span class="font-bold text-right">${stocks.stockExpress}${
            //         stocks.stockExpressMax ? "+" : ""
            //     }</span>
            //     </div>`);
            // }
            if ("undefined" !== typeof stocks.stockManufacturer &&
                0 < stocks.stockManufacturer) {
                cell.push(`<div class="grid grid-flow-col">
                    <span>${(0, index_1.trans)("manufacturer", "manufacturer", "tyre_search")}</span>
                    <span class="font-bold text-right">
                        ${stocks.stockManufacturer}${stocks.stockManufacturerMax ? "+" : ""}
                    </span>
                </div>`);
            }
            if ("undefined" !== typeof stocks.stockHun && 0 < stocks.stockHun) {
                cell.push(`<div class="grid grid-flow-col">
                    <span>${(0, index_1.trans)("hungarian", "hungarian", "tyre_search")}</span>
                    <span class="font-bold text-right">${stocks.stockHun}${stocks.stockHunMax ? "+" : ""}</span>
                </div>`);
            }
            if ("undefined" !== typeof stocks.stockIhle && 0 < stocks.stockIhle) {
                cell.push(`<div class="grid grid-flow-col">
                    <span>${(0, index_1.trans)("remote", "remote", "tyre_search")}</span>
                    <span class="font-bold text-right">${stocks.stockIhle}${stocks.stockIhleMax ? "+" : ""}</span>
                </div>`);
            }
            if ("undefined" !== typeof stocks.status) {
                if ("limited" === stocks.status) {
                    cell.push(`<button type="button" class="button-limited">
                        ${(0, index_1.trans)("limited_stock", "Limited stock", "tyre_filter")}
                        <span class="tooltip"><span class="icon marso-icon-info-circle"></span></span>
                    </button>`);
                }
                else if ("orderable" === stocks.status) {
                    cell.push(`<button type="button" class="button-orderable">
                        ${(0, index_1.trans)("orderable", "Orderable", "tyre_filter")}
                        <span class="tooltip"><span class="icon marso-icon-info-circle"></span></span>
                    </button>`);
                }
            }
            return cell.length
                ? '<div class="relative">' + cell.join("") + "</div>"
                : "";
        },
        sortable: true,
        sortingFunction: (productA, productB) => {
            var _a, _b;
            let stockA = (_a = productA.stocks.stockCountry) !== null && _a !== void 0 ? _a : 0;
            let stockB = (_b = productB.stocks.stockCountry) !== null && _b !== void 0 ? _b : 0;
            return stockA - stockB;
        },
    },
    {
        key: "cart",
        visible: true,
        label: "",
    },
];
